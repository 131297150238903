import React from "react";
import Menu from '../components/Menu';
import styles from '../cssmodules/Template.module.css'
import Header from "./Header";
import WeatherCard from "./WeatherCard";
import PrivatPays from "./PrivatPays";
import ModalUser from "../usercach/modalUser";
import OurTarif from "./OurTarif";

const Template = ({ children }) => {
  // Generate snowflakes dynamically
  const snowflakes = Array.from({ length: 10 }).map((_, index) => (
    <div key={index} className={styles.snowflake}>❄</div>
  ));

  return (
    <div>
      <Header />

      <div className="menu">
        <Menu />
      </div>

      <div className="body">
        <div className="left item">
          <PrivatPays />
          <WeatherCard />
        </div>
        <div className="centr item">
          {children} {/* Display Home and About components here */}
        </div>
        <div className="right item">
          <ModalUser />
          <OurTarif />
        </div>
      </div>

      {/* Snowflakes container */}
      <div className={styles.snowfall}>
        {snowflakes}
      </div>
    </div>
  );
};

export default Template;
