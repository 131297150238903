import styles from '../cssmodules/ourtarif.module.css'

const OurTarif = () => {
  return (
    <div className={styles.block}>
        <h1>Наші тарифи</h1>
        <ul className={styles.list}>
            <li> GEPON-100 (100 Мб/с - 230 грн.міс)</li>
            <li> GEPON-300 (300 Мб/с - 300 грн.міс)</li>
            <li>Статичний IP - 400 грн.міс</li>
        </ul>
    </div>
  )
}

export default OurTarif