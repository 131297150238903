import { TfiHome } from "react-icons/tfi";
import { FaPhoneVolume } from "react-icons/fa6";
import { FaTelegram } from "react-icons/fa";
import { SiViber } from "react-icons/si";
import { MdOutlineAlternateEmail } from "react-icons/md";
import One from '../images/photo_2024-12-26_09-36-01.jpg'
import Two from '../images/photo_2024-12-26_09-36-08.jpg'
import styles from '../cssmodules/about.module.css'

const About = () => {
  return (
    <>
      <h2>Наші контакти:</h2>

      <div className={styles.container}>
        {/* Левый столбец с текстом */}
        <div className={styles.textContent}>
          <ul className={styles.list}>
            <li><TfiHome className={styles.adr} /> <b>м.Суми вул.Новоміської сотні,1(Рибалко)</b></li>
            <li><FaPhoneVolume /> <b>050 634 80 93</b></li>
            <li><FaTelegram className={styles.telegram}/> <b>050 634 80 93</b></li>
            <li><SiViber className={styles.viber} /> <b>050 634 80 93</b></li>
            <li><MdOutlineAlternateEmail /> <b>info@online.sumy.ua</b></li>
          </ul>
        </div>

        {/* Правый столбец с изображениями */}
        <div className={styles.photo}>
          <img src={One} alt="Фото 1" />
          <img src={Two} alt="Фото 2" />
        </div>
      </div>
    </>
  );
}

export default About;

